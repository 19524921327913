<template>
  <header class="mobile-header">
    <div class="header-content">
      <div class="logo-container">
        <div class="logo-text">Agent Connect</div>
      </div>
      <div class="language-container">
        <select v-model="$i18n.locale">
          <option value="zh">中文</option>
          <option value="en">English</option>
        </select>
      </div>
    </div>
    <nav class="nav-container">
      <ul>
        <li><router-link :to="{ path: '/', hash: '#card-product' }">{{ $t('product.title') }}</router-link></li>
        <li><a :href="$t('product.techDocLink')" target="_blank">{{ $t('product.techDoc') }}</a></li>
        <li><router-link :to="{ path: '/', hash: '#card-introduction' }">{{ $t('about.title') }}</router-link></li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'MobileHeader'
}
</script>

<style scoped>
.mobile-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 12vh; /* 高度增加一倍 */
  display: flex;
  flex-direction: column; /* 改为纵向排列 */
  justify-content: space-between;
  padding: 10px;
  z-index: 50;
  background: rgba(0, 0, 0, 0.8);
}

.header-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  background: rgba(206, 215, 233, 0.1); 
  padding: 0.3rem 0.3rem;
  border-radius: 0.5rem;
  backdrop-filter: blur(10px);
}

.logo-text {
  color: white;
  font-size: 1rem;
  font-style: italic;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(206, 215, 233, 0.01);
  padding: 0.5rem;
  border-radius: 0.5rem;
  backdrop-filter: blur(10px);
  width: 100%; /* 确保导航容器占满宽度 */
}

nav ul {
  list-style: none;
  display: flex;
  flex-direction: row; /* 改为水平排列 */
  justify-content: center; /* 居中对齐 */
  gap: 20px; /* 增加间距 */
  margin: 0;
  padding: 0;
  width: 100%; /* 确保列表占满宽度 */
}

nav a {
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  padding: 0.5rem;
}

.language-container {
  background: rgba(206, 215, 233, 0.1);
  padding: 0.3rem;
  border-radius: 0.5rem;
  backdrop-filter: blur(10px);
}

.language-container select {
  background: transparent;
  color: white;
  border: none;
  font-size: 0.9rem;
  cursor: pointer;
}

.language-container select option {
  background: rgba(0, 0, 0, 0.8);
}
</style>
