<template>
  <header class="header">
    <div class="header-content">
      <div class="logo-container">
        <div class="logo-text">Agent Connect</div>
      </div>
      <nav class="nav-container">
        <ul>
          <li><router-link :to="{ path: '/', hash: '#card-product' }">{{ $t('product.title') }}</router-link></li>
          <li><a :href="$t('product.techDocLink')" target="_blank">{{ $t('product.techDoc') }}</a></li>
          <li><router-link :to="{ path: '/', hash: '#card-introduction' }">{{ $t('about.title') }}</router-link></li>
        </ul>
      </nav>
      <div class="language-container">
        <select v-model="currentLanguage" @change="changeLanguage">
          <option value="en">English</option>
          <option value="zh">中文</option>
        </select>
      </div>
    </div>
    <div class="nav-background"></div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      currentLanguage: 'en' // 将默认语言设置为英语
    }
  },
  created() {
    // 在组件创建时设置默认语言
    this.$i18n.locale = this.currentLanguage;
  },
  methods: {
    changeLanguage() {
      // 当语言选择改变时更新 i18n 的 locale
      this.$i18n.locale = this.currentLanguage;
    }
  }
}
</script>

<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 11vh; /* 增加高度 */
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 10px;
  z-index: 50;
}

.header-content {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1; /* 提高层级 */
}

.logo-container {
  position: absolute;
  left: 5%;
  transform: translateX(-5%);
  background: rgba(206, 215, 233, 0.1); 
  padding: 0.3rem 0.3rem;
  border-radius: 0.5rem;
  z-index: 2;
  backdrop-filter: blur(10px);
}

.logo-text {
  color: white; /* 设置文本颜色 */
  font-size: 1.2rem; /* 设置文本大小 */
  font-style: italic; /* 设置文本为斜体 */
  font-weight: bold; /* 设置文本为粗体 */
  font-family: 'Arial', sans-serif; /* 设置字体 */
  background: rgba(0, 0, 0, 0); /* 设置背景色 */
  padding: 10px; /* 设置内边距 */
  border-radius: 10px; /* 设置圆角 */
  opacity: 0.8; /* 设置透明度 */
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23%; /* 将宽度调整为 25% */
  margin: 0 auto; /* 居中显示 */
  background: rgba(206, 215, 233, 0.01); /* 添加背景色 */
  padding: 1rem 0.3rem; /* 增加内边距 */
  border-radius: 0.5rem; /* 添加圆角 */
  z-index: 2; /* 提高层级 */
  backdrop-filter: blur(10px); /* 添加毛玻璃效果 */
}

nav ul {
  list-style: none;
  display: flex;
  gap: 0px; /* 导航链接之间的间距 */
  margin: 0;
  padding: 0;
}

nav a {
  color: white;
  text-decoration: none;
  font-size: 1rem; /* 使用相对单位 */
  padding: 0.1rem 0.7rem; /* 增加链接的内边距，使点击区域更大 */
}

.nav-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 12vh;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  z-index: 0; /* 降低层级 */
}

.language-container {
  position: absolute;
  right: 5%;
  transform: translateX(5%);
  background: rgba(206, 215, 233, 0.1);
  padding: 0.3rem 0.3rem;
  border-radius: 0.5rem;
  z-index: 2;
  backdrop-filter: blur(10px);
}

.language-container select {
  background: transparent;
  color: white;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.language-container select option {
  background: rgba(0, 0, 0, 0.8);
}
</style>
