<template>
  <div id="app">
    <component :is="isMobile ? 'MobileHeader' : 'Header'" />
    <router-view />
  </div>
</template>

<script>
import Header from './components/Header.vue';
import MobileHeader from './components/MobileHeader.vue';

export default {
  components: {
    Header,
    MobileHeader
  },
  data() {
    return {
      isMobile: false
    };
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768; // 768px 作为移动端的阈值
    }
  }
};
</script>

<style>
body {
  margin: 0;
  font-family: Arial, sans-serif;
}
</style>
