<template>
  <section :id="id" class="card">
    <img src="@/assets/product.jpeg" class="card-image" alt="Product Image">
    <div class="card-content">
      <h1 class="title">{{ $t('about.title') }}</h1>
      <p class="description">{{ $t('about.description') }}</p>
      <div class="buttons">
        <button class="btn primary" @click="handleClick">{{ $t('about.contact') }}</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    id: String,
    title: String
  },
  methods: {
    handleClick() {
      const url = 'https://egp0uc2jnx.feishu.cn/share/base/form/shrcnTwsdxfjenFKuWIS9K0EH9d';
      window.open(url, '_blank');
    }
  }
};
</script>

<style scoped>
.card {
  display: flex;
  height: 100vh;
  background: rgb(20, 20, 20);
  box-sizing: border-box; /* 确保内边距和边框包含在元素总高度内 */
}

.card-image {
  height: 100%;
  object-fit: cover;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  color: white;
}

.title {
  font-family: Arial, sans-serif; /* 设置标题字体 */
  font-size: 2rem; /* 设置标题字体大小 */
  margin: 0 0 1rem 0; /* 设置标题边距 */
}

.description {
  font-family: Arial, sans-serif; /* 设置描述字体 */
  font-size: 1.2rem; /* 设置描述字体大小 */
  line-height: 1.6; /* 设置行高 */
  margin: 0 0 2rem 0; /* 设置描述边距 */
}

.buttons {
  display: flex;
  gap: 2rem;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
}

.primary {
  background-color: blue;
  color: white;
}

.secondary {
  background-color: white;
  color: black;
}
</style>
