<template>
  <section class="mobile-hero">
    <h1 class="title">{{ $t('hero.title') }}</h1>
    <p class="subtitle">{{ $t('hero.subtitle') }}</p>
    <div class="buttons">
      <button class="btn secondary" @click="goToProduct">{{ $t('hero.learnMore') }}</button>
      <button class="btn primary" @click="applyForBeta">{{ $t('hero.applyBeta') }}</button>
    </div>
  </section>
</template>

<style scoped>
.mobile-hero {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  background-image: url('@/assets/background.jpeg');
  background-size: cover;
  background-position: center;
}
.title {
  font-size: 2rem; /* 设置标题字体大小 */
}
.subtitle {
  font-size: 1rem; /* 设置副标题字体大小 */
  margin-bottom: 2rem; /* 设置副标题和按钮之间的间距 */
}
.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
}
.primary {
  background-color: blue;
  color: white;
}
.secondary {
  background-color: white;
  color: black;
}
</style>

<script>
export default {
  methods: {
    goToProduct() {
      this.$router.push({ path: '/', hash: '#card-product' });
    },
    applyForBeta() {
      const url = 'https://egp0uc2jnx.feishu.cn/share/base/form/shrcniiUhrL9l7kF34t9aF6oiVb';
      window.open(url, '_blank');
    }
  }
}
</script>
