import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/Home.vue';
// import About from './views/About.vue';
// import News from './views/News.vue';
// import Join from './views/Join.vue';

const routes = [
  { path: '/', name: 'Home', component: Home }
  // { path: '/about', name: 'About', component: About },
  // { path: '/news', name: 'News', component: News },
  // { path: '/join', name: 'Join', component: Join }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,  // 使用 URL 的 hash 来选择元素
        behavior: 'smooth'  // 滚动行为平滑过渡
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
