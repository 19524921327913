<template>
  <div class="mobile-footer">
    <span>{{ $t('footer.copyright') }}</span>
    <a href="https://beian.miit.gov.cn/" target="_blank">{{ $t('footer.icp') }}</a>
    <a href="https://beian.mps.gov.cn/#/query/webSearch?code=33011002017762" rel="noreferrer" target="_blank">{{ $t('footer.police') }}</a>
  </div>
</template>

<script>
export default {
  name: 'MobileFooter'
};
</script>

<style scoped>
.mobile-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f4f8;
  padding: 10px;
  text-align: center;
  font-size: 14px; /* 统一文字大小 */
  color: #000; /* 统一文字颜色 */
}

.mobile-footer a {
  margin: 5px 0;
  text-decoration: none;
  color: #000; /* 统一文字颜色 */
}
</style>
